$primary: #3a86ff;
$secondary: #8338ec;
$success: #3d9837;
$warning: #ffbe0b;
$light: #f1faee;
$dark: #000;
$white: #ffffff;
$grey: #d3d3d3;
$danger: #ff006e;
$orange: #fb5607;




// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  warning: $warning,
  success: $success,
  light: $light,
  dark: $dark,
  danger: $danger,
  orange: $orange,
  grey: $grey
);

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');
* {
  font-family: 'Montserrat';
}

.pointer {
  cursor: pointer;
}

.weight-thin {
  font-weight: 300;
}

.weight-bold {
  font-weight: bold;
}

.noDecoration {
  text-decoration: none;
}

.fc-event {
  cursor: pointer;
}

.hidden {
  display: none;
}

.rotater {
  //transform: rotateY(180deg);
}

.sub-player {
  -webkit-appearance: none;
  //transform: rotateY(180deg);
}

.sub-player video::-webkit-media-controls {
  //transform: rotateY(180deg) !important;
}

.valign-middle {
  display: table-cell;

  vertical-align: middle;
}

.video-overlay {
  position: relative;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 5px 5px;
  font-size: 20px;
  font-family: Helvetica;
  color: #fff;
  background-color: rgba(50, 50, 50, 0.3);
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 3s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.wavey {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.02);
}

.halfish {
  background: linear-gradient(green 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-success {
  background: linear-gradient($success 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-primary {
  background: linear-gradient($primary 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-secondary {
  background: linear-gradient($secondary 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-warning {
  background: linear-gradient($warning 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-dark {
  background: linear-gradient($dark 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-danger {
  background: linear-gradient($danger 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.halfish-orange {
  background: linear-gradient($orange 50%, transparent 50%) no-repeat !important;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.top-round {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.cardBtn {
  cursor: pointer;
  padding: 5px;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
}

.color-btn {
  cursor: pointer;
  padding: 5px;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.hoverable:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.9);
}

.my-link:hover {
  text-decoration: underline !important;
}

.st-primary {
  background-color: rgba(58, 134, 255, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-secondary {
  background-color: rgba(131, 56, 236, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-success {
  background-color: rgba(61, 152, 55, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-warning {
  background-color: rgba(255, 190, 11, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-dark {
  background-color: rgba(0, 0, 0, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-danger {
  background-color: rgba(255, 0, 110, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.st-orange {
  background-color: rgba(251, 86, 7, 0.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.round-btm{
  border-radius: 1rem;
}
